<template>
  <div>
    <vs-table search stripe border description :sst="true" :data="listData" :max-items="table.length"
      :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              ">
            <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button size="small" color="success" type="border" @click="handleExport('excel')">Excel</vs-button>
        </div>
      </template>
      <template slot="thead">
        <vs-th sort-key="sku_code">SKU Code</vs-th>
        <vs-th sort-key="sku_name">SKU Name</vs-th>
        <vs-th sort-key="warehouse_code">Warehouse Code</vs-th>
        <vs-th sort-key="storage_location">Storage Location</vs-th>
        <vs-th sort-key="status_sku">Status_sku SKU</vs-th>
        <vs-th sort-key="uom">UOM</vs-th>
        <vs-th sort-key="division">Division</vs-th>
        <vs-th sort-key="principal">Principal</vs-th>
        <vs-th sort-key="product_hierarchy">Product Hierarchy</vs-th>
        <vs-th sort-key="product_hierarchy_descr">Product Hierarchy Descr</vs-th>
        <vs-th sort-key="batch">Batch</vs-th>
        <vs-th sort-key="last_sales_order_number">Last Sales Order Number</vs-th>
        <vs-th sort-key="sales_order_count">Sales Order Count</vs-th>
        <vs-th sort-key="stock">Stock</vs-th>
        <vs-th sort-key="last_good_rec">Last Good Rec</vs-th>
        <vs-th sort-key="quantity_on_hand">Quantity On Hand</vs-th>
        <vs-th sort-key="quantity_reserved">Quantity Reserved</vs-th>
        <vs-th sort-key="quantity_available">Quantity Available</vs-th>
        <vs-th sort-key="map_value">MAP Value</vs-th>
        <vs-th sort-key="inventory_value">Inventory Value</vs-th>
        <vs-th sort-key="currency">Currency</vs-th>
        <vs-th sort-key="less_than_16_days">{{ table_amount_header }}</vs-th>
        <vs-th sort-key="period_1_amount">{{ table_amount }}</vs-th>
        <vs-th sort-key="between_16_and_30_days">Qty 16 - 30 Days</vs-th>
        <vs-th sort-key="period_2_amount">Amount 16 - 30 Days</vs-th>
        <vs-th sort-key="between_31_and_60_days">Qty 31 - 60 Days</vs-th>
        <vs-th sort-key="period_3_amount">Amount 31 - 60 Days</vs-th>
        <vs-th sort-key="between_61_and_90_days">Qty 61 - 90 Days</vs-th>
        <vs-th sort-key="period_4_amount">Amount 61 - 90 Days</vs-th>
        <vs-th sort-key="between_91_and_120_days">Qty 91 - 200 Days</vs-th>
        <vs-th sort-key="period_5_amount">Amount 91 - 200 Days</vs-th>
        <vs-th sort-key="between_121_and_150_days">Qty 121 - 150 Days</vs-th>
        <vs-th sort-key="period_6_amount">Amount 121 - 150 Days</vs-th>
        <vs-th sort-key="greater_than_150_days"> Qty > 150 Days </vs-th>
        <vs-th sort-key="period_7_amount">Amount > 150 Days</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <!-- <vs-td> </vs-td> -->
          <vs-td :data="data[indextr].sku_code">
            {{ data[indextr].sku_code }}
          </vs-td>
          <vs-td :data="data[indextr].sku_name">
            {{ data[indextr].sku_name }}
          </vs-td>
          <vs-td :data="data[indextr].warehouse_code">
            {{ data[indextr].warehouse_code }}
          </vs-td>
          <vs-td :data="data[indextr].storage_location">
            {{ data[indextr].storage_location }}
          </vs-td>
          <vs-td :data="data[indextr].status_sku">
            {{ data[indextr].status_sku }}
          </vs-td>
          <vs-td :data="data[indextr].uom">
            {{ data[indextr].uom }}
          </vs-td>
          <vs-td :data="data[indextr].division">
            {{ data[indextr].division }}
          </vs-td>
          <vs-td :data="data[indextr].principal">
            {{ data[indextr].principal }}
          </vs-td>
          <vs-td :data="data[indextr].product_hierarchy">
            {{ data[indextr].product_hierarchy }}
          </vs-td>
          <vs-td :data="data[indextr].product_hierarchy_descr">
            {{ data[indextr].product_hierarchy_descr }}
          </vs-td>
          <vs-td :data="data[indextr].batch">
            {{ data[indextr].batch }}
          </vs-td>
          <vs-td :data="data[indextr].last_sales_order_number">
            {{ data[indextr].last_sales_order_number }}
          </vs-td>
          <vs-td :data="data[indextr].sales_order_count">
            {{ data[indextr].sales_order_count }}
          </vs-td>
          <vs-td :data="data[indextr].stock">
            {{ data[indextr].stock }}
          </vs-td>
          <vs-td :data="data[indextr].last_good_rec">
            {{ data[indextr].last_good_rec }}
          </vs-td>
          <vs-td :data="data[indextr].quantity_on_hand">
            {{ data[indextr].quantity_on_hand }}
          </vs-td>
          <vs-td :data="data[indextr].quantity_reserved">
            {{ data[indextr].quantity_reserved }}
          </vs-td>
          <vs-td :data="data[indextr].quantity_available">
            {{ data[indextr].quantity_available }}
          </vs-td>
          <vs-td :data="data[indextr].map_value">
            {{ data[indextr].map_value }}
          </vs-td>
          <vs-td :data="data[indextr].inventory_value">
            {{ data[indextr].inventory_value }}
          </vs-td>
          <vs-td :data="data[indextr].currency">
            {{ data[indextr].currency }}
          </vs-td>
          <vs-td :data="data[indextr].less_than_16_days">
            {{ data[indextr].less_than_16_days }}
          </vs-td>
          <vs-td :data="data[indextr].period_1_amount">
            {{ data[indextr].period_1_amount }}
          </vs-td>
          <vs-td :data="data[indextr].between_16_and_30_days">
            {{ data[indextr].between_16_and_30_days }}
          </vs-td>
          <vs-td :data="data[indextr].period_2_amount">
            {{ data[indextr].period_2_amount }}
          </vs-td>
          <vs-td :data="data[indextr].between_31_and_60_days">
            {{ data[indextr].between_31_and_60_days }}
          </vs-td>
          <vs-td :data="data[indextr].period_3_amount">
            {{ data[indextr].period_3_amount }}
          </vs-td>
          <vs-td :data="data[indextr].between_61_and_90_days">
            {{ data[indextr].between_61_and_90_days }}
          </vs-td>
          <vs-td :data="data[indextr].period_4_amount">
            {{ data[indextr].period_4_amount }}
          </vs-td>
          <vs-td :data="data[indextr].between_91_and_120_days">
            {{ data[indextr].between_91_and_120_days }}
          </vs-td>
          <vs-td :data="data[indextr].period_5_amount">
            {{ data[indextr].period_5_amount }}
          </vs-td>
          <vs-td :data="data[indextr].between_121_and_150_days">
            {{ data[indextr].between_121_and_150_days }}
          </vs-td>
          <vs-td :data="data[indextr].period_6_amount">
            {{ data[indextr].period_6_amount }}
          </vs-td>
          <vs-td :data="data[indextr].greater_than_150_days">
            {{ data[indextr].greater_than_150_days }}
          </vs-td>
          <vs-td :data="data[indextr].period_7_amount">
            {{ data[indextr].period_7_amount }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination style="padding-top: 5px" :total="totalPage" v-model="setPage" />
  </div>
</template>
<script>
import moment from "moment";
import { mapState, mapActions } from "vuex/dist/vuex.common.js";

export default {
  components: {},
  props:{
    territoryIDs: {
      type: Array,
    },
    storageIDs: {
      type: Array,
    },
    skuCodeIDs: {
      type: Array,
    },
    batchIDs: {
      type: Array,
    },
    agingGroupIDs: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      table_amount_header: "Qty <  16 days",
      table_amount: "Amount < 16 Days"
    };
  },
  methods: {
    ...mapActions({ 
      getAgingPropductReport: 'reportAgingProduct/getAgingPropductReport',
      generateAgingProductReport: 'reportAgingProduct/generateAgingProductReport',
    }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      if (this.draw > 0) {
        this.$vs.loading();
        this.getAgingPropductReport({
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          territory: this.territoryIDs,
          storage:this.storageIDs,
          sku_code:this.skuCodeIDs,
          batch:this.batchIDs,
          aging_group:this.agingGroupIDs,
        })
          .then(() => {
            this.$vs.loading.close();
          });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    handleExport(file) {
            this.$vs.loading();
            this.generateAgingProductReport({
              length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          territory: this.territoryIDs,
          storage:this.storageIDs,
          sku_code:this.skuCodeIDs,
          batch:this.batchIDs,
          aging_group:this.agingGroupIDs,
                }).then(() => {
                    this.$vs.loading.close();
                }).catch(() => {
                    this.$vs.loading.close();
                })
            
        },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    draw() {
      this.getData();
    },
    generateReport(val) {
      if (val && val.status === 'OK') {
        this.$vs.notify({
          color: "success",
          title: "Processing",
          text: val.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: val.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    totalRecord(val) {
      this.table.total = val
    },
  },
  computed: {
    ...mapState({
      listData: (state) => state.reportAgingProduct.tableData,
      totalPage: (state) => state.reportAgingProduct.totalPage,
      generateReport: (state) => state.reportAgingProduct.generateReport,
      totalRecord: (state) => state.reportAgingProduct.total_record,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>